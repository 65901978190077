<template>
    <aside :class="{showing: $store.state.convertorSettingsShowing}">
        <button type="button" class="backdrop" @click="$store.commit('toggleConvertorSettings')"></button>
        <section :class="{showing: $store.state.convertorSettingsShowing}">
            <div class="field">
                <label>size:</label>
                <input type="number" v-model="$store.state.convertorSettings.size" autocomplete="off">
            </div>
            <div class="field">
                <label>opacity:</label>
                <input type="number" v-model="$store.state.convertorSettings.opacity" autocomplete="off">
            </div>
            <div class="field">
                <label>pixel size:</label>
                <input type="number" v-model="$store.state.convertorSettings.pixelSize" autocomplete="off">
            </div>
            <button type="button" class="btn-default" @click="$store.commit('toggleConvertorSettings')">close</button>
        </section>
    </aside>
</template>

<script>
    export default {
        name: 'convertorSettings',
        components: {
            
        }
    }
</script>

<style lang="less" scoped>
    aside {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transition: all .5s ease;
        z-index: 2;
        
        &:not(.showing) {
            opacity: 0;
            pointer-events: none;
        }
        
        .backdrop {
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0,0,0, .9);
            width: 100%;
            height: 100%;
            border: 0;
            z-index: 1;
        }
        
        section {
            position: relative;
            background-color: var(--gray-darkest);
            width: 100%;
            max-width: 480px;
            height: 100%;
            margin-left: auto;
            padding: 24px;
            overflow: auto;
            transition: all .5s ease;
            z-index: 2;
            
            &:not(.showing) {
                transform: translateX(100%);
            }
            
            .field {
                &:not(:last-child) {
                    margin-bottom: 24px;
                }
                
                label {
                    display: block;
                    
                    &:not(:last-child) {
                        margin-bottom: 12px;
                    }
                }
                
                input {
                    background-color: var(--gray-darker);
                    color: #fff;
                    width: 100%;
                    padding: 6px;
                    border: 0;
                }
            }
        }
    }
</style>
