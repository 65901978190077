<template>
    <ul>
        <li v-for="demon in $store.state.demons" :key="demon">
            <button type="button" @click="$store.commit('selectDemon', demon)">
                <img :src="'./assets/images/seals/' + demon + '.png'">
                <span v-text="demon"></span>
            </button>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'demonList'
    }
</script>

<style lang="less" scoped>
    ul {
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 24px;
        list-style: none;

        li {
            background-color: #fff;
            min-width: 0;
            min-height: 0;
            padding: 12px;
            display: block;

            button {
                position: relative;
                background-color: transparent;
                width: 100%;
                margin: 0;
                padding: 0 0 100% 0;
                border: 0;
                display: block;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center center;
                    z-index: 1;
                }

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    background-color: #000;
                    color: var(--red);
                    margin: 0;
                    padding: 6px 12px;
                    display: block;
                    font-size: 20px;
                    text-align: center;
                    transform: translate(-50%, -50%);
                    transition: all .5s ease;
                    z-index: 2;
                }

                &:not(:hover) {
                    span {
                        opacity: 0;
                    }
                }
            }
        }
    }
</style>
