<template>
    <main :class="{'translate-left': $store.getters.translatePageLeft}">
        <div class="container" v-if="!$store.state.selectedDemon">
            <p>These are all taken from <a href="https://en.wikipedia.org/wiki/List_of_sigils_of_demons" target="_blank">this wikipedia page</a>. We're not taking credit for anything.</p>
            <demonList/>
        </div>
        <div class="container" v-if="$store.state.selectedDemon">
            <demonConvertor :demon="$store.state.selectedDemon"/>
        </div>
    </main>
</template>

<script>
    import demonList from '@/components/demonList.vue'
    import demonConvertor from '@/components/demonConvertor.vue'
    
    export default {
        name: 'Home',
        data() {},
        components: {
            demonList,
            demonConvertor
        }
    }
</script>
