import { createStore } from 'vuex'

export default createStore({
	state: {
		demons: [
            'Aim',
            'Agares',
            'Allocer',
            'Amdusias',
            'Amon',
            'Amy',
            'Andras',
            'Andrealphus',
            'Andromalius',
            
            'Bael',
            'Vassago',
            'Samigina',
            'Marbas',
            'Valefor',
            'Barbatos',
            'Paimon',
            'Buer',
            'Gusion',
            'Sitri',
            'Beleth',
            'Leraie',
            'Eligos',
            'Zepar',
            'Botis',
            'Bathin',
            'Sallos',
            'Purson',
            'Morax',
            'Ipos',
            'Naberius',
            'Glasya-Labolas',
            'Bune',
            'Ronove',
            'Berith',
            'Forneus',
            'Foras',
            'Asmodeus',
            'Gaap',
            'Furfur',
            'Marchosias',
            'Stolas',
            'Phenex',
            'Halphas',
            'Malphas',
            'Raum',
            'Focalor',
            'Vepar',
            'Sabnock',
            'Shax',
            'Vine',
            'Bifrons',
            'Vual',
            'Haagenti',
            'Crocell',
            'Furcas',
            'Balam',
            'Caim',
            'Murmur',
            'Orobas',
            'Gremory',
            'Ose',
            'Orias',
            'Vapula',
            'Zagan',
            'Valac',
            'Haures',
            'Kimaris',
            'Belial',
            'Decarabia',
            'Seere',
            'Dantalion',
            'Astaroth',
            'Lucifer'
        ],
        selectedDemon: false,
        demonListShowing: false,
        convertorSettings: {
            size: 140,
            opacity: 150,
            pixelSize: 12
        },
        convertorSettingsShowing: false
	},
	mutations: {
        selectDemon(state, demon) {
			this.state.selectedDemon = demon;
            this.state.demonListShowing = false;
		},
        
        toggleConvertorSettings() {
            this.state.convertorSettingsShowing = !this.state.convertorSettingsShowing;
        },
        
        toggleDemonList() {
            this.state.demonListShowing = !this.state.demonListShowing;
        }
	},
    getters: {
        noPageScroll: state => {
            return state.convertorSettingsShowing || state.demonListShowing;
        },
        translatePageLeft: state => {
            return state.convertorSettingsShowing;
        }
    },
	actions: {
	},
	modules: {
	},
	methods: {
	}
})
