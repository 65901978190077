<template>
    <section>
        <h1 class="text-center">{{ demon }}</h1>
        <figure :class="{loading: imageLoading}">
            <img :src="'./assets/images/seals/' + demon + '.png'" @load="imageLoaded">
            <canvas></canvas>
        </figure>
    </section>
</template>

<script>
    export default {
        name: 'demonConvertor',
        props: {
            demon: String
        },
        data() {
            return {
                imageLoading: true,
                resultCanvas: false
            };
        },
        computed: {
            resultWidth: function()
            {
                return this.$store.state.convertorSettings.size * this.$store.state.convertorSettings.pixelSize;
            },
            resultHeight: function()
            {
                return this.$store.state.convertorSettings.size * this.$store.state.convertorSettings.pixelSize;
            }
        },
        methods: {
            imageLoaded: function(event)
            {
                this.imageLoading = false;
                this.convertDemon(event.target);
            },
            
            convertDemon: function(img)
            {
                let canvas = document.createElement('canvas');
                canvas.width = this.$store.state.convertorSettings.size;
                canvas.height = this.$store.state.convertorSettings.size;
                
                this.drawImage(canvas, img);

                this.resultCanvas.width = this.resultWidth;
                this.resultCanvas.height = this.resultHeight;
                
                this.fillBackground(this.resultCanvas, '#000');

                let resultX = 0,
                    resultY = 0,
                    sourceData = this.getImageData(canvas);

                for (let i = 0; i < sourceData.length; i +=  4) {
                    if (sourceData[i + 3] >= this.$store.state.convertorSettings.opacity) {
                        if (
                            sourceData[i] == 0 &&
                            sourceData[i + 1] == 0 &&
                            sourceData[i + 2] == 0
                        ) {
                            this.fillMesh(this.resultCanvas, '#f00', resultX, resultY, 2);
                            //this.fillRect(this.resultCanvas, '#f00', resultX, resultY, this.$store.state.convertorSettings.pixelSize, this.$store.state.convertorSettings.pixelSize);
                        }
                    }

                    resultX += this.$store.state.convertorSettings.pixelSize;
                    if (resultX == this.resultWidth) {
                        resultY += this.$store.state.convertorSettings.pixelSize;
                        resultX = 0;
                    }
                }
            },
            
            getImageData: function(canvas)
            {
                return canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height).data;
            },
            
            clearCanvas: function(canvas)
            {
                canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
            },
            
            drawImage: function(canvas, image)
            {
                this.clearCanvas(canvas);
                canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
            },
            
            fillBackground: function(canvas, color)
            {
                this.clearCanvas(canvas);
                this.fillRect(canvas, color, 0, 0, canvas.width, canvas.height);
            },
            
            fillMesh: function(canvas, color, x, y, size)
            {
                console.log(this.hexToRgba(color, .5));
                this.fillRect(canvas, this.hexToRgba(color, .5), x, y, this.$store.state.convertorSettings.pixelSize, this.$store.state.convertorSettings.pixelSize);
                
                for (let j = y + size / 2; j <= y + this.$store.state.convertorSettings.pixelSize - size / 2; j += 2 * size) {
                    for (let i = x + size / 2; i <= x + this.$store.state.convertorSettings.pixelSize - size / 2; i += 2 * size) {
                        this.fillRect(canvas, color, i, j, size, size);
                    }
                }
            },
            
            fillRect: function(canvas, color, x, y, width, height)
            {
                let context = canvas.getContext('2d');
                context.fillStyle = color;
                context.fillRect(x, y, width, height);
            },
            
            hexToRgba: function(hex, opacity)
            {
                if (hex.length < 7) {
                    let newHex = hex.split('');
                    hex = newHex[0] + newHex[1] + newHex[1] + newHex[2] + newHex[2] + newHex[3] + newHex[3];
                }
                
                let hexArray = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                if (hexArray) {
                    return 'rgba(' + parseInt(hexArray[1], 16) + ', ' + parseInt(hexArray[2], 16) + ', ' + parseInt(hexArray[3], 16) + ', ' + opacity + ')';
                } else {
                    return 'rgba(0, 0, 0, 0)';
                }
                
                /*
                return hexArray ? {
                    r: parseInt(hexArray[1], 16),
                    g: parseInt(hexArray[2], 16),
                    b: parseInt(hexArray[3], 16)
                } : null;
                */
            }
        },
        mounted() {
            this.resultCanvas = document.querySelector('canvas');
        }
    }
</script>

<style lang="less" scoped>
    section {
        background-color: var(--gray-darkest);
        padding: 24px;
        
        figure {
            position: relative;
            background-color: #fff;
            width: 100%;
            margin: 0;
            padding: 0 0 100% 0;
            display: block;
            transition: all .5s;
            z-index: 1;
            
            &:not(:last-child) {
                margin-bottom: 24px;
            }
            
            &.loading {
                opacity: .5;
            }
            
            img {
                display: none;
            }
            
            canvas {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
            }
        }
    }
</style>
