<template>
    <aside :class="{showing: $store.state.demonListShowing}">
        <div class="top">
            <div class="container">
                <h2>select another demon to process</h2>
            </div>
        </div>
        <div class="middle">
            <div class="container">
                <demonList/>
            </div>
        </div>
        <div class="bottom">
            <div class="container">
                <button type="button" class="btn-default" @click="$store.commit('toggleDemonList')">close</button>
            </div>
        </div>
    </aside>
</template>

<script>
    import demonList from '@/components/demonList.vue'
    
    export default {
        name: 'demonMenu',
        components: {
            demonList
        }
    }
</script>

<style lang="less" scoped>
    aside {
        position: fixed;
        top: 0;
        right: 0;
        background-color: var(--gray-darkest);
        width: 100%;
        height: 100%;
        overflow: auto;
        display: grid;
        grid-gap: 24px;
        transition: all .5s ease;
        z-index: 2;
        
        &:not(.showing) {
            opacity: 0;
            pointer-events: none;
        }
        
        .top {
            position: sticky;
            top: 0;
            background-color: var(--gray-darker);
            padding: 24px;
            text-align: center;
            z-index: 2;
        }
        
        .center {
            position: relative;
            z-index: 1;
        }
        
        .bottom {
            position: sticky;
            bottom: 0;
            background-color: var(--gray-darker);
            padding: 24px;
            text-align: center;
            z-index: 2;
        }
    }
</style>
